import { get, post } from './http'

export const index = () => {
  const url = '/mobile/credits/index_new'
  return get(url)
}

export const product = (params) => {
  const url = '/mobile/credits'
  return get(url, params)
}

export const search = (params) => {
  const url = '/mobile/credits/search'
  return get(url, params)
}

export const addFavorite = (id) => {
  const url = '/mobile/credits/add_favorite/' + id
  return post(url)
}

export const removeFavorite = (id) => {
  const url = '/mobile/credits/remove_favorite/' + id
  return post(url)
}

export const detail = (params) => {
  const url = '/mobile/credits/detail'
  return get(url, params)
}

export const addressList = () => {
  const url = '/mobile/credits/address'
  return get(url)
}

export const editAddress = (id, data) => {
  const url = '/mobile/credits/edit_address/' + id
  return post(url, data)
}

export const deleteAddress = (id) => {
  const url = '/mobile/credits/remove_address/' + id
  return post(url)
}

export const addAddress = (data) => {
  const url = '/mobile/credits/add_address'
  return post(url, data)
}

export const creditsLog = (params) => {
  const url = '/mobile/credits/log'
  return get(url, params)
}

export const collection = () => {
  const url = '/mobile/credits/favorite'
  return get(url)
}

export const submit = (data) => {
  const url = '/mobile/credits/submit'
  return post(url, data)
}

export const orderList = () => {
  const url = '/mobile/credits/order'
  return get(url)
}

export const expressInfo = (params) => {
  const url = '/mobile/credits/kuaidi'
  return get(url, params)
}
